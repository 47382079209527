<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1213 13.5355C10.9497 15.4882 9.05025 15.4882 7.87868 13.5355C6.70711 11.5829 6.70711 8.41709 7.87868 6.46447C9.05025 4.51184 10.9497 4.51184 12.1213 6.46447M6 8.5H10M6 11.5H10M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
